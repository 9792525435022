.rc-tooltip {
  --arrow-size: 12px;

  position: absolute;
  z-index: 30;
  display: block;

  &-hidden {
    display: none;
  }

  &-placement-bottom,
  &-placement-bottomLeft,
  &-placement-bottomRight,
  &-placement-top,
  &-placement-topLeft,
  &-placement-topRight {
    padding: var(--arrow-size) 0;
  }

  &-placement-left,
  &-placement-leftTop,
  &-placement-leftBottom,
  &-placement-right,
  &-placement-rightTop,
  &-placement-rightBottom {
    padding: 0 var(--arrow-size);
  }
}

.rc-tooltip-inner {
  padding: 0.5rem;
  border-radius: 2px;
  box-shadow: var(--border-shadow-elevation-1);
  border: solid 1px var(--primary-dark-blue-200);
  background-color: var(--primary-dark-000);
}

.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    border: var(--arrow-size) solid transparent;
    border-bottom-color: var(--primary-dark-000);
  }
}

.rc-tooltip {
  &-placement-top &-arrow,
  &-placement-topLeft &-arrow,
  &-placement-topRight &-arrow {
    bottom: 0;
    margin-left: calc(0 - var(--arrow-size));
    border-width: var(--arrow-size) var(--arrow-size) 0;
    border-top-color: var(--primary-dark-blue-200);
    &::after {
      left: calc(0px - var(--arrow-size));
      top: calc(0px - var(--arrow-size) - 1.5px);
      transform: rotate(180deg);
    }
  }

  &-placement-top &-arrow {
    left: calc(50% - var(--arrow-size));
  }

  &-placement-topLeft &-arrow {
    left: 15%;
  }

  &-placement-topRight &-arrow {
    right: 15%;
  }

  &-placement-right &-arrow,
  &-placement-rightTop &-arrow,
  &-placement-rightBottom &-arrow {
    left: 0;
    margin-top: calc(0 - var(--arrow-size));
    border-width: var(--arrow-size) var(--arrow-size) var(--arrow-size) 0;
    border-right-color: var(--primary-dark-blue-200);

    &::after {
      top: calc(0px - var(--arrow-size));
      left: calc(0px - var(--arrow-size) + 1.5px);
      transform: rotate(-90deg);
    }
  }

  &-placement-right &-arrow {
    top: calc(50% - var(--arrow-size));
  }

  &-placement-rightTop &-arrow {
    top: 15%;
    margin-top: 0;
  }

  &-placement-rightBottom &-arrow {
    bottom: 15%;
  }

  &-placement-left &-arrow,
  &-placement-leftTop &-arrow,
  &-placement-leftBottom &-arrow {
    right: 0;
    margin-top: calc(0 - var(--arrow-size));
    border-width: var(--arrow-size) 0 var(--arrow-size) var(--arrow-size);
    border-left-color: var(--primary-dark-blue-200);

    &::after {
      top: calc(0px - var(--arrow-size));
      right: calc(0px - var(--arrow-size) + 1.5px);
      transform: rotate(90deg);
    }
  }

  &-placement-left &-arrow {
    top: calc(50% - var(--arrow-size));
  }

  &-placement-leftTop &-arrow {
    top: 15%;
    margin-top: 0;
  }

  &-placement-leftBottom &-arrow {
    bottom: 15%;
  }

  &-placement-bottom &-arrow,
  &-placement-bottomLeft &-arrow,
  &-placement-bottomRight &-arrow {
    top: 0;
    margin-left: calc(0 - var(--arrow-size));
    border-width: 0 var(--arrow-size) var(--arrow-size);
    border-bottom-color: var(--primary-dark-blue-200);

    &::after {
      left: calc(0px - var(--arrow-size));
      top: calc(0px - var(--arrow-size) + 1.5px);
    }
  }

  &-placement-bottom &-arrow {
    left: calc(50% - var(--arrow-size));
  }

  &-placement-bottomLeft &-arrow {
    left: 15%;
  }

  &-placement-bottomRight &-arrow {
    right: 15%;
  }
}
