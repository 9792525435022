/* TODO: consider self-hosting fonts */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=block');

@font-face {
  font-family: Monorale;
  font-weight: 700;
  font-style: normal;
  src: url('./assets/fonts/Monorale-Bold.woff') format('woff');
}
body {
  font-family: var(--font-family-primary);
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  font-size: 16px;
  --font-family-primary: 'Open Sans', sans-serif;
  --font-family-secondary: 'Monorale', sans-serif;
  --content-max-width: 1600px;
}

:root {
  --primary-dark-blue-900: #000f21;
  --primary-dark-blue-800: #001f43;
  --primary-dark-blue-700: #002754;
  --primary-dark-blue-600: #003776;
  --primary-dark-blue-500: #003f87;
  --primary-dark-blue-400: #33659f;
  --primary-dark-blue-300: #668cb7;
  --primary-dark-blue-200: #99b2cf;
  --primary-dark-blue-100: #ccd9e7;
  --primary-dark-blue-50: #ebf0f6;
  --primary-dark-blue-25: #f5f7fa;

  --primary-blue-900: #00293d;
  --primary-blue-800: #003f5f;
  --primary-blue-700: #005681;
  --primary-blue-600: #006ca3;
  --primary-blue-500: #008ed6;
  --primary-blue-400: #33a5de;
  --primary-blue-300: #66bbe6;
  --primary-blue-200: #99d2ef;
  --primary-blue-100: #cce8f7;
  --primary-blue-50: #ebf6fc;

  --primary-green-900: #244b0d;
  --primary-green-800: #326812;
  --primary-green-700: #469419;
  --primary-green-600: #4da21c;
  --primary-green-500: #5bbf21;
  --primary-green-400: #7ccc4d;
  --primary-green-300: #9dd97a;
  --primary-green-200: #bde5a6;
  --primary-green-100: #def2d3;
  --primary-green-50: #f2faee;

  --secondary-pink-900: #600132;
  --secondary-pink-800: #71013b;
  --secondary-pink-700: #93014d;
  --secondary-pink-600: #b4025e;
  --secondary-pink-500: #d60270;
  --secondary-pink-400: #de358d;
  --secondary-pink-300: #e667a9;
  --secondary-pink-200: #ef9ac6;
  --secondary-pink-100: #f7cce2;
  --secondary-pink-50: #fae5f0;

  --secondary-purple-900: #460033;
  --secondary-purple-800: #57003f;
  --secondary-purple-700: #68004b;
  --secondary-purple-600: #790057;
  --secondary-purple-500: #9b0070;
  --secondary-purple-400: #af338d;
  --secondary-purple-300: #c366a9;
  --secondary-purple-200: #d799c6;
  --secondary-purple-100: #ebcce2;
  --secondary-purple-50: #f5e5f0;
  --secondary-orange-500: #f96807;
  --secondary-orange-400: #fa8639;
  --secondary-orange-300: #fba46a;
  --secondary-orange-200: #fdc39c;
  --secondary-orange-100: #fee5d3;
  --secondary-orange-50: #fef1e9;

  --primary-dark-400: #333f4d;
  --primary-dark-300: #65798e;
  --primary-dark-200: #8e9eb1;
  --primary-dark-100: #c2cbd5;
  --primary-dark-50: #e4e4e4;
  --primary-dark-000: #ffffff;

  --additional-red-600: #b91c1c;
  --additional-red-500: #ff143c;
  --additional-red-300: #ffb8c4;
  --additional-red-100: #ffe7eb;

  --overlay-dark-blue-80: rgba(0, 15, 33, 0.8);

  --european-yellow: #ffcc00;
}

:root {
  --border-radius: 4px;
  --border-default: solid 1px var(--primary-dark-blue-200);
  --border-light: solid 1px var(--primary-dark-blue-100);
  --border-shadow-elevation-1: 0 2px 6px -2px rgba(0, 38, 73, 0.16);
  --border-shadow-elevation-2: 0 32px 50px -20px rgba(0, 38, 73, 0.06);
  --border-shadow-elevation-3: 0 0 47px -12px rgba(177, 177, 179, 1);
}

.page__header {
  margin: 0.5rem 0 1.5rem;
  font-family: var(--font-family-secondary);
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.67;
  letter-spacing: 0.4px;
  color: var(--primary-dark-blue-500);
}

.page__header::after {
  content: '';
  display: block;
  width: 2.5rem;
  height: 3px;
  background-image: linear-gradient(94deg, var(--primary-green-400), var(--primary-blue-500));
}

.section-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.overlay {
  position: fixed;
  top: 0;
  z-index: 10000 !important;
  background-color: var(--overlay-dark-blue-80);
  width: 100%;
  height: 100%;
}

.ReactModal__Content {
  opacity: 0;
  transform: translateY(50px) translateX(-50%) !important;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transform: translateY(-50%) translateX(-50%) !important;
}

.ReactModal__Content--before-close {
  opacity: 0;
  transform: translateX(-100px) !important;
}

.button-primary {
  background-color: var(--primary-dark-blue-500) !important;
  border: 1px transparent solid !important;
  color: var(--primary-dark-000) !important;
}

.button-primary:hover {
  background-color: var(--primary-dark-blue-600) !important;
}

.button-primary-white {
  border: var(--border-default);
  color: var(--primary-dark-blue-900);
}

.button-primary-pink {
  background-color: var(--secondary-pink-500) !important;
  border: 1px transparent solid !important;
  color: var(--primary-dark-000) !important;
}

.button-primary-pink:hover {
  background-color: var(--secondary-pink-400) !important;
}

.zsiq_custommain {
  margin-bottom: 3.75rem !important;
}

.calculator-open {
  .zsiq_custommain {
    display: none !important;
  }
}
